.field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.field span {
  font-size: 14px;
  line-height: 22px;
}
.field span:last-child {
  font-family: Tahoma, "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
    "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  margin-left: 8px;
  color: #1777d1;
  font-size: 18px
}
